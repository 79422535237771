/*产品详情、大图展示、首页的父组件*/
<template>
  <div>
    <template v-if="isMobile">
      <NavHeaderMobile :isShowLeftMenu="false"></NavHeaderMobile>
    </template>
    <template v-else>
      <nav-header></nav-header>
    </template>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <nav-footer v-if="!$route.meta.hideFooter"></nav-footer>
    <er-code></er-code>
  </div>
</template>
<script>
import { htmlFontSize, isMobile  } from '../util/common'
import NavHeaderMobile from "../components/NavHeader-mobile.vue";
import NavFooter from "../components/NavFooter.vue";
import NavHeader from "../components/NavHeader.vue";
import ErCode from "../components/erCode";
export default {
  components: {ErCode, NavHeader, NavFooter, NavHeaderMobile },
  name: "home",
  data() { 
    return {
      isMobile: false
    }
  },
  mounted() {
    if (isMobile()) {
      this.isMobile = true
      htmlFontSize()
    } else { 
      this.isMobile = false
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
