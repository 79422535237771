<!--全局引用的组件，扫码联系顾问-->
<template>
  <div class="erCode">
    <div v-if="showCode" class="put-box">
      <div class="code-head">联系顾问</div>
      <div class="code-content">
        <template v-if="localStorageType==1">
          <img src="/imgs/er-wei-ma02-his.jpg" alt=""/>
        </template>
        <template v-else-if="localStorageType==2">
          <img src="/imgs/er-wei-ma02-com.jpg" alt=""/>
        </template>
      </div>
      <div class="put" @click="showCode = false">收起</div>
    </div>

    <div v-else>
      <img style="width: 64px;cursor: pointer" src="/imgs/putCode.png" @click="showCode = true" />
    </div>
  </div>
</template>

<script>
export default {
  name: "erCode",
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 1,
      showCode: true,
      showPutBox: false,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/scss/base.scss";
@import "../assets/scss/mixin.scss";
@import "../assets/scss/config.scss";
//@media screen and (max-width: 1500px){
//  .erCode {
//    right: 32px;
//  }
//}
//@media screen and (min-width: 1499px){
//  .erCode {
//    left: calc(50% + 637px);
//  }
//}
.erCode {
  position: fixed;
  right: 32px;
  bottom: 64px;
  z-index: 4;
  filter: drop-shadow(0px 3px 13px rgba(0, 56, 255, 0.1));
  .code-head {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    background-color: #202839;
    border-radius: 8px 8px 0 0;
    position: relative;
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      background-color: #FFFFFF;
      border-radius: 50%;
      position: absolute;
      left: 16PX;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: #FFFFFF;
      border-radius: 50%;
      position: absolute;
      right: 16PX;;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .code-content {
    background-color: #FFFFFF;
    border-radius: 0 0 8px 8px;
    img {
      width: 88px;
      margin: 8px 12px;
    }
  }
  .put {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #FFFFFF;
    color: #202839;
    font-size: 12px;
    font-weight: 600;
    margin: 0 auto;
    border-radius: 0 0 6px 6px;
    position: relative;
    cursor: pointer;
    filter: drop-shadow(0px 3px 13px rgba(0, 56, 255, 0.1));
    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #202839;
    }
  }
}
</style>
