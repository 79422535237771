/* 一级顶部（产品详情、大图展示、首页会用到）组件*/
<template>
  <div class="header">
    <div class="container">
      <!-- 顶部nav使用vue动态渲染数据 -->
      <div class="nav-header">


        <div v-if="localStorageType==1" class="nav-header-logo">
          <a @click="$router.push('/new/home')"></a>
          <div class="nav-header-search">
            <!--          <div class="search-box">-->
            <!--            <el-input placeholder="请输入内容" v-model="inputValue">-->
            <!--              <template slot="append">搜索</template>-->
            <!--            </el-input>-->
            <!--          </div>-->
            <!--          <div class="provide-demand">提交需求</div>-->
          </div>
        </div>

        <div v-if="localStorageType==2" class="nav-header-logo2">
          <a @click="$router.push('/new/home')"></a>
          <div class="nav-header-search">
            <!--          <div class="search-box">-->
            <!--            <el-input placeholder="请输入内容" v-model="inputValue">-->
            <!--              <template slot="append">搜索</template>-->
            <!--            </el-input>-->
            <!--          </div>-->
            <!--          <div class="provide-demand">提交需求</div>-->
          </div>
        </div>

        <div class="mouse-style" v-if="getUserInfo && getUserInfo.userName">
          <span class="user-name">欢迎, {{ getUserInfo.userName }}</span>
          <span v-if="$route.name === 'product'">
            <i class="iconfont-new icon-zhanghu"></i
            ><a @click="() => jumpPage('account')"> 账户中心</a>
          </span>
          <span v-else>
            <i class="iconfont-new icon-shouye"></i
            ><a @click="() => jumpPage('account')"> 返回首页</a>
          </span>
        </div>
        <!-- <span class="mouse-style" v-if="$route.name === 'product'">
          <i class="iconfont-new icon-zixun"></i><a @click="() => jumpPage('/information')"> 医疗资讯</a>
        </span> -->
        <!-- <span class="mouse-style" v-else>
          <i class="iconfont-new icon-shouye"></i><a @click="() => jumpPage('/account')" > 返回首页</a>
        </span> -->

        <div class="tab-header-user" v-if="!getUserInfo.userName">
          <span class="login-btn" @click="isShowLogin()">登录</span>
        </div>

        <div class="loginOut-box" v-if="getUserInfo.userName">
          <div @click="loginOut">退出</div>
        </div>
      </div>
    </div>
    <login-model ref="loginModel" @loginSuccess="loginSuccess"></login-model>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { Input, MessageBox } from "element-ui";
import { loginOut } from "../api/login";
import LoginModel from "./loginModel";
export default {
  name: "nav-header",
  components: {
    LoginModel,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
  },
  data() {
    return {
      inputValue: "",
      localStorageType: localStorage.getItem("type") || 2,
    };
  },

  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),
    jumpPage(name) {
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      } else {
        this.$router.push({ name });
      }
    },

    loginSuccess() {
      this.updateUserInfo();
      // console.log(this.$store.state.username, this.updateUserInfo())
    },

    loginOut() {
      let redirectedFrom = this.$route.path;
      MessageBox.confirm("您是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            if (res.code === 200) {
              localStorage.clear();
              this.setToken("");
              this.updateUserInfo("");
              if (redirectedFrom.includes("/account")) {
                this.$router.push("/");/*退出登录跳转到首页*/
              }

              this.$message({
                type: "success",
                message: "退出成功",
              });
            } else {
              this.$message.error("退出失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
      // console.log('0909')
      // localStorage.clear()
      // this.setToken('')
      // this.updateUserInfo('')
      // this.$router.push('/product')
    },
    isShowLogin() {
      this.$refs.loginModel.showModel();
      this.$store.commit("setIsShow", 1);
      // this.$router.push("/login");
    },
    // isShowRegister() {
    //   this.$router.push("/login");
    //   this.$store.commit("setIsShow", 2);
    // },
  },
  mounted() {
    let token = localStorage.getItem("token") || "";
    if (!token) {
      this.setToken("");
      this.updateUserInfo("");
    }
    // let userInfo = JSON.parse(localStorage.getItem('userInfo') || null)
    // if(userInfo) this.$set(this, 'userName', userInfo.username)
    // this.$set(this,'userName',this.getUserInfo.username)
    //
    // this.userName = this.userInfo.username
  },

  computed: {
    // 这里防止在拉去数据后使用计算属性的方式来解决无数据状况
    username() {
      return this.$store.state.username;
    },
    ...mapGetters("user", ["getUserInfo"]),
  },
};
</script>
<style lang="scss">
//@import "../assets/scss/base.scss";
//@import "../assets/scss/mixin.scss";
//@import "../assets/scss/config.scss";
@import "../assets/scss/navheader.scss";
</style>
