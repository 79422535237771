/* 一级顶部（产品详情、大图展示、首页会用到）组件*/
<template>
  <div class="header">
    <div class="container">
      <!-- 顶部nav使用vue动态渲染数据 -->
      <div class="nav-header">
        <div v-if="localStorageType==1" class="nav-header-logo">
          <a @click="$router.push('/new/home')"></a>
        </div>
        <div v-if="localStorageType==2" class="nav-header-logo2">
          <a @click="$router.push('/new/home')"></a>
        </div>
        <div class="nav-header-menu">
          <div class="menu">
            <p @click="showUser" style="display: flex; align-items: center">
              <i class="el-icon-user" v-if="!isShowUser"></i>
              <i class="el-icon-close" v-else></i>
              <span style="font-size: 12px">{{
                getUserInfo.userName || ""
              }}</span>
            </p>
            <p
              @click="showMenuList"
              v-if="isShowLeftMenu"
              style="margin-left: 10px"
            >
              <i class="el-icon-s-unfold" v-if="!isShowMenu"></i>
              <i class="el-icon-close" v-else></i>
            </p>
          </div>
          <div class="header-menu-list" v-if="isShowMenu">
            <ul>
              <li v-for="item in pageList" :key="item.name">
                <a
                  :href="'#' + item.id"
                  @click="checkActive(item.id, $event)"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>

          <div class="user-box" v-show="isShowUser">
            <ul>
              <li class="user-name" v-if="getUserInfo.userName">
                欢迎, {{ getUserInfo.userName }}
              </li>
              <li
                class="login-btn"
                v-if="!getUserInfo.userName"
                @click="showUser(), isShowLogin()"
              >
                登录
              </li>
              <li @click="loginOut" v-else>退出登录</li>
              <li @click="openHome" v-show="path !== '/product-mobile'">
                返回首页
              </li>
            </ul>
          </div>
          <div
            class="header-menu-bg"
            @click="showMenuList"
            v-show="isShowMenu"
          ></div>
          <div
            class="header-menu-bg"
            @click="showUser"
            v-show="isShowUser"
          ></div>
        </div>
      </div>
    </div>
    <login-model ref="loginModel" @loginSuccess="loginSuccess"></login-model>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { Input, MessageBox } from "element-ui";
import { loginOut } from "../api/login";
import LoginModel from "./loginModel";
export default {
  name: "nav-header",
  components: {
    LoginModel,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
  },
  props: {
    pageList: {
      default: () => {
        return [
          { name: "公司首页", page: "/index", id: "content_img1" },
          { name: "公司介绍", page: "/", id: "content_box1" },
          { name: "公司产品", page: "", id: "content_product_box" },
          { name: "用户案例", page: "", id: "content_img2" },
          { name: "企业资讯", page: "/information", id: "/information" },
          { name: "联系方式", page: "", id: "content_connect_box" },
        ];
      },
    },
    isShowLeftMenu: {
      default: true,
    },
  },
  data() {
    return {
      isShowMenu: false,
      inputValue: "",
      isShowUser: false,
      path: this.$route.path,
      localStorageType: localStorage.getItem("type") || 1,
    };
  },
  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),
    cancelLayer(e) {
      let target = e.target;
      if (target.className === "header-menu-list") {
        this.isShowMenu = false;
      }
    },
    showMenuList() {
      this.isShowUser = false;
      this.isShowMenu = !this.isShowMenu;
    },
    showUser() {
      this.isShowMenu = false;
      this.isShowUser = !this.isShowUser;
    },
    checkActive(id, e) {
      if (id === "/information") {
        this.$router.push({ path: id });
      } else {
        e = e || event;
        e.preventDefault();
        this.activeId = id;
        let targetEle = document.getElementById(id);
        let h = targetEle.offsetTop;
        this.isShowMenu = false;
        window.scrollTo(0, h - 100);
      }
    },
    jumpPage() {
      if (this.$route.name !== "product") {
        this.$router.push("/new/home");
      } else {
        this.$router.push("/account");
      }
    },

    loginSuccess() {
      this.updateUserInfo();
    },

    loginOut() {
      let redirectedFrom = this.$route.path;
      MessageBox.confirm("您是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            if (res.code === 200) {
              localStorage.clear();
              this.setToken("");
              this.updateUserInfo("");
              if (redirectedFrom.includes("/account")) {
                this.$router.push("/product");
              }

              this.$message({
                type: "success",
                message: "退出成功",
              });
            } else {
              this.$message.error("退出失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
    },
    isShowLogin() {
      // this.$router.push("/login");
      this.$refs.loginModel.showModel();
      this.$store.commit("setIsShow", 1);
    },
    isShowRegister() {
      this.$router.push("/login");
      this.$store.commit("setIsShow", 2);
    },
    openHome() {
      this.$router.push("/product-mobile");
    },
  },
  mounted() {
    let token = localStorage.getItem("token") || "";
    if (!token) {
      this.setToken("");
      this.updateUserInfo("");
    }
  },

  computed: {
    // 这里防止在拉去数据后使用计算属性的方式来解决无数据状况
    username() {
      return this.$store.state.username;
    },
    ...mapGetters("user", ["getUserInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // 头部最上层样式
  border-bottom: 1px solid #f6f8fc;
  background-color: #ffffff;
  z-index: 100;
  // 头部下面样式
  .nav-header {
    height: 0.88rem;
    display: flex;
    align-items: center;
    // @include flex();
    .nav-header-logo {
      @include setsize(100%, 0.88rem);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 22px;
      > a {
        display: inline-block;
        vertical-align: middle;
        @include setsize(2.45rem, 0.46rem);
        &::before {
          content: " ";
          display: inline-block;
          @include setimg("/imgs/tt-logo-yl.png");
          @include setsize(2.45rem, 0.46rem);
          cursor: pointer;
          //transition: all 0.3s;
        }
      }
    }
    .nav-header-logo2 {
      @include setsize(100%, 0.88rem);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 22px;
      > a {
        display: inline-block;
        vertical-align: middle;
        @include setsize(2.45rem, 0.46rem);
        &::before {
          content: " ";
          display: inline-block;
          @include setimg("/imgs/tt-logo-qy.png");
          @include setsize(2.45rem, 0.46rem);
          cursor: pointer;
          //transition: all 0.3s;
        }
      }
    }
    .nav-header-menu {
      .menu {
        overflow: hidden;
        font-size: 24px;
        display: flex;
        align-items: center;
      }
    }

    .header-menu-list,
    .user-box {
      box-sizing: border-box;
      position: fixed;
      top: 0.88rem;
      left: 0;
      width: 100%;
      height: auto;
      min-height: 2rem;
      z-index: 99;
      ul {
        margin: 0;
        background-color: #fff;
        padding: 0 0.56rem 0.56rem;
        li {
          box-sizing: border-box;
          height: 0.87rem;
          line-height: 0.87rem;
          color: #4850ff;
          font-weight: 500;
          font-size: 0.28rem;
          border-bottom: 1px solid #979797;
        }
      }
    }
    .user-box {
      background: #fff;
      height: 2rem;
    }
    .header-menu-bg {
      box-sizing: border-box;
      position: fixed;
      top: 0.88rem;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: rgba($color: #000000, $alpha: 0.3);
    }

    .mouse-style {
      cursor: pointer;
    }
    .loginOut-box {
      cursor: pointer;
      color: #ffffff;
      padding: 8px 15px;
      border-radius: 4px;
      background-color: $colorA;
    }
  }
  .user-name {
    color: #4850ff;
    margin-right: 30px;
  }
}

.container {
  box-sizing: border-box;
  width: 100vw;
  padding: 0 0.2rem;
}
</style>
